import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { formAtom, tokenAtom } from '../../../core/config/atoms';

import { useAlert } from 'react-alert';
import { Scrollbars } from 'react-custom-scrollbars';
import Lottie from 'lottie-react';
import Moment from 'moment';
import { Dialog } from '@headlessui/react';

import animBtnLoading from '../../../assets/anim/anim-btnLoading.json';

import ServicePlan from '../../../services/servicePlan';
import ServiceApplication from '../../../services/serviceApplication';
import ServiceUtility from '../../../services/serviceUtility';

export default function PanelApplicationVerification(props) {

    const [form, setForm] = useRecoilState(formAtom);
    const [token] = useRecoilState(tokenAtom);

    const [members, setMembers] = useState([]);

    const [url, setURL] = useState('');

    const [loading, setLoading] = useState(true);
    const [submit, setSubmit] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();
    const alert = useAlert();
    const fileService = new ServiceUtility();
    const planService = new ServicePlan();
    const appService = new ServiceApplication();

    const goBack = () => {
        navigate(`/home/applications`);
    }

    const getIdType = (type) => {
        switch (type) {
            case "1":
                return "Tax ID";
            case "2":
                return "Address Prof";
            case "3":
                return "Driver Licence";
            default:
                return "NA";
        }
    }

    const getmStatus = (type) => {
        switch (type) {
            case "1":
                return "Single";
            case "2":
                return "Married";
            case "3":
                return "Widowed";
            case "4":
                return "Divorced";
            default:
                return "NA";
        }
    }

    const getVheType = (type) => {
        switch (type) {
            case "1":
                return "4 Wheel";
            case "2":
                return "2 Wheel";
            default:
                return "NA";
        }
    }

    const loadData = (ln) => {
        (async function () {
            var body = {
                'lang': ln
            };
            var res = await planService.getPlans(body, token);
            if (res['status']) {
                setMembers(res.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
            setLoading(false);
        })();
    }

    const getIsPlanMember = (id) => {
        if (form.data.membershipInfo) {
            if (id === "nill") {
                return false;
            } else {
                return true;
            }
        }
        return false;
    }

    const getPlanById = (id) => {
        const plans = members.find((mem) => mem._id === id)
        return plans;
    }

    const viewDocument = (fileurl) => {
        setURL(fileurl);
        setIsOpen(true);
    }

    const Reject = () => {
        setSubmit(true);
        let promis = [];

        if (form.data.guardianInfo) {
            if (form.data.guardianInfo.gardPic) {
                let grdBody = { "path": form.data.guardianInfo.gardPic.fileurl }
                promis.push(fileService.deleteFile(grdBody, token));
            }
        }

        if (form.data.emrgencyInfo) {
            if (form.data.emrgencyInfo.emAPic) {
                let emABody = { "path": form.data.emrgencyInfo.emAPic.fileurl }
                promis.push(fileService.deleteFile(emABody, token));
            }

            if (form.data.emrgencyInfo.emBPic) {
                let emBBody = { "path": form.data.emrgencyInfo.emBPic.fileurl }
                promis.push(fileService.deleteFile(emBBody, token));
            }
        }

        if (form.data.identityInfo) {
            let idA = { "path": form.data.identityInfo.identityDoc.fileurl }
            promis.push(fileService.deleteFile(idA, token));

            let idB = { "path": form.data.identityInfo.frontPic.fileurl }
            promis.push(fileService.deleteFile(idB, token));

            let idC = { "path": form.data.identityInfo.smilePic.fileurl }
            promis.push(fileService.deleteFile(idC, token));

            let idD = { "path": form.data.identityInfo.profilePic.fileurl }
            promis.push(fileService.deleteFile(idD, token));
        }

        if (form.data.vehicleInfo) {
            let vheA = { "path": form.data.vehicleInfo.frontPic.fileurl }
            promis.push(fileService.deleteFile(vheA, token));

            let vheB = { "path": form.data.vehicleInfo.sidePic.fileurl }
            promis.push(fileService.deleteFile(vheB, token));

            if (form.data.vehicleInfo.backPic) {
                let vheC = { "path": form.data.vehicleInfo.backPic.fileurl }
                promis.push(fileService.deleteFile(vheC, token));
            }
        }

        Promise.all(promis).then((ress) => {
            let body = {
                fid: form._id,
            }
            appService.deleteApplication(body, token).then((res) => {
                setSubmit(false);
                if (res['status']) {
                    alert.show('Application rejected!', { type: 'success' });
                    goBack();
                } else {
                    alert.show('Server error please try again', { type: 'error' });
                }
            });
        });
    }

    const Accept = () => {
        setSubmit(true);
        let body = {
            "fid": form._id,
        }
        if (form.appType === "Form 1A") {
            appService.acceptForm1A(body, token).then((res) => {
                setSubmit(false);
                if (res.status) {
                    alert.show("Application approved!", { type: 'success' });
                    goBack();
                } else {
                    alert.show("Server error, please try again!", { type: 'error' });
                }
            });
        } else {
            appService.acceptForm1B(body, token).then((res) => {
                setSubmit(false);
                if (res.status) {
                    alert.show("Application approved!", { type: 'success' });
                    goBack();
                } else {
                    alert.show("Server error, please try again!", { type: 'error' });
                }
            });
        }
    }

    useEffect(() => {
        props.onTap();
        loadData('en');
    }, []);

    return (
        <div className="w-full h-screen p-2 flex flex-col bg-gray-50">
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-4xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Document viewer &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpen(false);
                        }}>
                            <i className="las la-times text-sm"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <div>
                        <img src={url} alt='Document' className='w-full rounded border-2' />
                    </div>
                    <hr className='my-2' />
                </div>
            </Dialog>
            {
                !loading && <Scrollbars>
                    <div className="grid grid-cols-12 grid-gap-2 p-3 overflow-y-scroll">
                        <div className='col-span-12'>
                            <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                                <i className="las la-long-arrow-alt-left text-xl"></i>
                                <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all applications</h4>
                            </div>
                            <div className="intro-y mt-4">
                                <h3 className="text-2xl font-sans font-bold text-gray-800">Taxi Driver : {form.appType}</h3>
                                <p className="mt-1 text-sm text-gray-600 mb-2">
                                    Please review all information related to driver.
                                </p>
                                <hr />
                            </div>
                        </div>
                    </div>
                    <div className='col-span-12 md:mx-8'>
                        <div className="col-span-12 mt-4">
                            <div className="intro-y w-full shadow-lg">
                                <div className='h-28 bg-sky-600 rounded-t-lg relative'>
                                    <div className='bg-sky-600 w-24 h-24 rounded-full flex items-center justify-center border-4 border-white absolute' style={{ bottom: '-3rem', left: '1rem' }}>
                                        <img src={form.data.identityInfo.profilePic.fileurl} className="w-full h-full object-cover rounded-full" />
                                    </div>
                                </div>
                                <div className='pb-4 px-4 mb-4 rounded-b-lg'>
                                    <div className='text-2xl font-bold ml-28'>{form.applicantName}</div>
                                    <div className='text-lg text-gray-600 ml-28'>{form.appType}</div>
                                </div>
                            </div>
                        </div>
                        <hr className='my-4' />
                        <div className='grid grid-cols-12 gap-8 w-full'>
                            {
                                form.data.identityInfo && <div className='col-span-12 md:col-span-4 shadow-lg bg-white p-4 rounded-md' style={{ height: 'fit-content' }}>
                                    <h1 className='text-black font-medium text-xl'>Profile Information</h1>
                                    <hr className='my-1' />
                                    <div className='flex items-center mt-2'>
                                        <img className="w-20 md:w-36 h-20 md:h-36 rounded border border-slate-950 mr-3 object-cover" src={form.data.identityInfo.frontPic.fileurl} />
                                        <img className="w-20 md:w-36 h-20 md:h-36 rounded border border-slate-950 mx-3 object-cover" src={form.data.identityInfo.smilePic.fileurl} />
                                        <img className="w-20 md:w-36 h-20 md:h-36 rounded border border-slate-950 mx-3 object-cover" src={form.data.identityInfo.profilePic.fileurl} />
                                    </div>
                                    <div className="text-black text-lg font-medium font-['Rubik'] mt-2">{form.data.personalInfo.fname} {form.data.personalInfo.mname} {form.data.personalInfo.lname}</div>
                                    <div className="text-slate-950 text-sm font-normal font-['Rubik'] mt-2">Email ID:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{form.data.personalInfo.email === "" ? "NA" : form.data.personalInfo.email}</span></div>
                                    <div className="text-slate-950 text-sm font-normal font-['Rubik']">DOB:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{form.data.personalInfo.dob === "" ? "NA" : Moment(form.data.personalInfo.dob, 'yyyy-MM-DD').format('DD MMM yyyy')}</span></div>
                                    <div className="text-slate-950 text-sm font-normal font-['Rubik']">Phone primary:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{form.data.personalInfo.phone === "" ? "NA" : "+243 " + form.data.personalInfo.phone}</span></div>
                                    <div className="text-slate-950 text-sm font-normal font-['Rubik']">Phone secondary:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{form.data.personalInfo.phoneb === "" ? "NA" : "+243 " + form.data.personalInfo.phoneb}</span></div>
                                    <div className="text-slate-950 text-sm font-normal font-['Rubik']">Marital Status:&nbsp;<span className="text-black text-sm font-medium font-['Rubik']">{getmStatus(form.data.personalInfo.mStatus)}</span></div>
                                    <div className="text-slate-950 text-sm font-normal font-['Rubik']">Number of children:&nbsp;<span className="text-black text-sm font-medium font-['Rubik']">{form.data.personalInfo.noc === "" ? "NA" : form.data.personalInfo.noc}</span></div>
                                    <div className="text-slate-950 text-sm font-normal font-['Rubik']">Physical Address 1</div>
                                    <div className="text-slate-400 text-sm font-normal font-['Rubik'] pr-6">{form.data.personalInfo.address === "" ? "NA" : form.data.personalInfo.address}</div>
                                    <div className="text-slate-950 text-sm font-normal font-['Rubik']">Physical Address 2</div>
                                    <div className="text-slate-400 text-sm font-normal font-['Rubik'] pr-6">{form.data.personalInfo.addressb === "" ? "NA" : form.data.personalInfo.addressb}</div>
                                    <div className="flex items-center justify-between mt-2">
                                        <div>
                                            <div className="text-slate-950 text-sm font-normal font-['Rubik']">{getIdType(form.data.identityInfo.identityType)}</div>
                                            <div className="text-slate-400 text-sm font-normal font-['Rubik']">{form.data.identityInfo.identityNumber}</div>
                                        </div>
                                        <div className="text-blue-600 text-sm font-medium font-['Rubik'] cursor-pointer" onClick={() => { viewDocument(form.data.identityInfo.identityDoc.fileurl); }}>View</div>
                                    </div>
                                </div>
                            }
                            <div className='col-span-12 md:col-span-8 shadow-lg bg-white p-4 rounded-md'>
                                <h1 className='text-black font-medium text-xl'>Additional Information</h1>
                                <hr className='my-1' />
                                <div className='grid grid-cols-3 md:grid-cols-6 gap-2'>
                                    {
                                        form.data.guardianInfo && <div className='col-span-3'>
                                            <div className="text-blue-600 text-lg font-medium font-['Rubik'] mt-2">Tutor Information</div>
                                            <div className='mt-2 flex'>
                                                {form.data.guardianInfo.gardPic && <img className="w-36 h-36 rounded border border-slate-950 mr-4" src={form.data.guardianInfo.gardPic.fileurl} />}
                                                {!form.data.guardianInfo.gardPic && <img className="w-36 h-36 rounded border border-slate-950 mr-4" src="https://via.placeholder.com/100x100?text=NA" />}
                                                <div>
                                                    <div className="text-black text-base font-medium font-['Rubik']">{form.data.guardianInfo.gardName === "" ? "NA" : form.data.guardianInfo.gardName}</div>
                                                    <div className="text-slate-950 text-sm font-normal font-['Rubik'] mt-2">Phone primary:&nbsp;<span className="text-black text-sm font-medium font-['Rubik']">{form.data.guardianInfo.gardPhone === "" ? "NA" : "+243 " + form.data.guardianInfo.gardPhone}</span></div>
                                                    <div className="text-slate-950 text-sm font-normal font-['Rubik']">Phone secondary:&nbsp;<span className="text-black text-sm font-medium font-['Rubik']">{form.data.guardianInfo.gardPhoneb === "" ? "NA" : "+243 " + form.data.guardianInfo.gardPhoneb}</span></div>
                                                    <div className="text-slate-950 text-sm font-normal font-['Rubik']">Physical Address</div>
                                                    <div className="text-slate-400 text-sm font-normal font-['Rubik']">{form.data.guardianInfo.gardaddress === "" ? "NA" : form.data.guardianInfo.gardaddress}</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        form.data.emrgencyInfo && <div className='col-span-3'>
                                            <div className="text-blue-600 text-lg font-medium font-['Rubik'] mt-4">Emergency Contact Person 1</div>
                                            <div className='mt-2 flex'>
                                                {form.data.emrgencyInfo.emAPic && <img className="w-36 h-36 rounded border border-slate-950 mr-4" src={form.data.emrgencyInfo.emAPic.fileurl} />}
                                                {!form.data.emrgencyInfo.emAPic && <img className="w-36 h-36 rounded border border-slate-950 mr-4" src="https://via.placeholder.com/100x100?text=NA" />}
                                                <div>
                                                    <div className="text-black text-base font-medium font-['Rubik']">{form.data.emrgencyInfo.emAName === "" ? "NA" : form.data.emrgencyInfo.emAName}</div>
                                                    <div className="text-slate-950 text-sm font-normal font-['Rubik'] mt-2">Phone primary:&nbsp;<span className="text-black text-sm font-medium font-['Rubik']">{form.data.emrgencyInfo.emAPhone === "" ? "NA" : "+243 " + form.data.emrgencyInfo.emAPhone}</span></div>
                                                    <div className="text-slate-950 text-sm font-normal font-['Rubik']">Phone secondary:&nbsp;<span className="text-black text-sm font-medium font-['Rubik']">{form.data.emrgencyInfo.emAPhoneb === "" ? "NA" : "+243 " + form.data.emrgencyInfo.emAPhoneb}</span></div>
                                                    <div className="text-slate-950 text-sm font-normal font-['Rubik']">Physical Address</div>
                                                    <div className="text-slate-400 text-sm font-normal font-['Rubik']">{form.data.emrgencyInfo.emAAddress === "" ? "NA" : form.data.emrgencyInfo.emAAddress}</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        form.data.emrgencyInfo && <div className='col-span-3'>
                                            <div className="text-blue-600 text-lg font-medium font-['Rubik'] mt-4">Emergency Contact Person 2</div>
                                            <div className='mt-2 flex'>
                                                {form.data.emrgencyInfo.emBPic && <img className="w-36 h-36 rounded border border-slate-950 mr-4" src={form.data.emrgencyInfo.emBPic.fileurl} />}
                                                {!form.data.emrgencyInfo.emBPic && <img className="w-36 h-36 rounded border border-slate-950 mr-4" src="https://via.placeholder.com/100x100?text=NA" />}
                                                <div>
                                                    <div className="text-black text-base font-medium font-['Rubik']">{form.data.emrgencyInfo.emBName === "" ? "NA" : form.data.emrgencyInfo.emBName}</div>
                                                    <div className="text-slate-950 text-sm font-normal font-['Rubik'] mt-2">Phone primary:&nbsp;<span className="text-black text-sm font-medium font-['Rubik']">{form.data.emrgencyInfo.emBPhone === "" ? "NA" : "+243 " + form.data.emrgencyInfo.emBPhone}</span></div>
                                                    <div className="text-slate-950 text-sm font-normal font-['Rubik']">Phone secondary:&nbsp;<span className="text-black text-sm font-medium font-['Rubik']">{form.data.emrgencyInfo.emBPhoneb === "" ? "NA" : "+243 " + form.data.emrgencyInfo.emBPhoneb}</span></div>
                                                    <div className="text-slate-950 text-sm font-normal font-['Rubik']">Physical Address</div>
                                                    <div className="text-slate-400 text-sm font-normal font-['Rubik']">{form.data.emrgencyInfo.emBAddress === "" ? "NA" : form.data.emrgencyInfo.emBAddress}</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <hr className='my-4' />
                        <div className='grid grid-cols-12 gap-8 w-full'>
                            {
                                form.data.vehicleInfo && <div className='col-span-12 md:col-span-4 shadow-lg bg-white p-4 rounded-md' style={{ height: 'fit-content' }}>
                                    <h1 className='text-black font-medium text-xl'>Vehicle Information</h1>
                                    <hr className='my-1' />
                                    <div className='flex items-center mt-2'>
                                        <img className="w-20 md:w-36 h-20 md:h-36 rounded border border-slate-950 mr-4" src={form.data.vehicleInfo.frontPic.fileurl} />
                                        <img className="w-20 md:w-36 h-20 md:h-36 rounded border border-slate-950 mr-4" src={form.data.vehicleInfo.sidePic.fileurl} />
                                        {form.data.vehicleInfo.backPic && <img className="w-20 md:w-36 h-20 md:h-36 rounded border border-slate-950 mr-4" src={form.data.vehicleInfo.backPic.fileurl} />}
                                        {!form.data.vehicleInfo.backPic && <img className="w-20 md:w-36 h-20 md:h-36 rounded border border-slate-950 mr-4" src="https://via.placeholder.com/100x100?text=NA" />}
                                    </div>
                                    <div className="text-black text-lg font-medium font-['Rubik'] mt-2">{form.data.vehicleInfo.vheManufacturer} - {form.data.vehicleInfo.vheType} {form.data.vehicleInfo.vheModel} - {form.data.vehicleInfo.vheColor}</div>
                                    <div className="text-slate-950 text-sm font-normal font-['Rubik'] mt-2">Chassis number:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{form.data.vehicleInfo.vheChNumer}</span></div>
                                    <div className="text-slate-950 text-sm font-normal font-['Rubik']">Registration number:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{form.data.vehicleInfo.vheRegNumer}</span></div>
                                    <div className="text-slate-950 text-sm font-normal font-['Rubik']">Taxi service number&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{form.data.vehicleInfo.vheServiceNumer}</span></div>
                                    <div className="text-slate-950 text-sm font-normal font-['Rubik']">Taxi Type:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{getVheType(form.data.vehicleInfo.vheTType)}</span></div>
                                    <div className="text-slate-950 text-sm font-normal font-['Rubik']">Numner of seats:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{form.data.vehicleInfo.vheSeat} Seats</span></div>
                                </div>
                            }
                            <div className='col-span-12 md:col-span-8'>
                                {
                                    form.data.membershipInfo && getIsPlanMember(form.data.membershipInfo.planId) && <div className='col-span-6 shadow-lg bg-white p-4 rounded-md' style={{ height: 'fit-content' }}>
                                        <h1 className='text-black font-medium text-xl'>Membership Information</h1>
                                        <hr className='my-1' />
                                        <div className='flex items-start'>
                                            <div className="text-xs font-medium text-gray-700 flex flex-col">
                                                <span className='text-lg font-medium'>{getPlanById(form.data.membershipInfo.planId).title} : <span className='text-lg text-green-600'>{getPlanById(form.data.membershipInfo.planId).rate.toFixed(2)} $</span> For {getPlanById(form.data.membershipInfo.planId).tenuare} Days</span>
                                                <spna>
                                                    {getPlanById(form.data.membershipInfo.planId).disc}
                                                </spna>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    form.data.signInfo && <div className='col-span-6 shadow-lg bg-white p-4 rounded-md mt-4' style={{ height: 'fit-content' }}>
                                        <h1 className='text-black font-medium text-xl'>Self Declaration</h1>
                                        <hr className='my-1' />
                                        <div className='text-sm'>Full Name: <span className='font-medium'>{form.data.signInfo.signName}</span></div>
                                        <div className='text-sm mt-1'>Place: <span className='font-medium'>{form.data.signInfo.signPlace}</span></div>
                                        <div className='text-sm mt-1'>Declaration Date: <span className='font-medium'>{Moment(form.updatedAt).format('DD MMM yyyy')}</span></div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="intro-y px-2 py-3 bg-gray-100 flex md:text-right mx-1 md:mx-8 my-4 md:my-8">
                        <button type="submit" className="inline-flex justify-center py-2 px-4 mr-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700" onClick={() => { Reject() }}>
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "REJECT APPLICATION"}
                        </button>
                        <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700" onClick={() => { Accept() }}>
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "CONFIRM APPLICATION"}
                        </button>
                    </div>
                    <div className='h-[60px]'></div>
                </Scrollbars>
            }
        </div>
    )
}
