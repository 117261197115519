import React, { useState, useEffect, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import Lottie from 'lottie-react';
import { Dialog } from '@headlessui/react';
import * as faceapi from 'face-api.js';

import ServiceApplication from '../../../../services/serviceApplication';
import ServiceUtility from '../../../../services/serviceUtility';

import animBtnLoading from '../../../../assets/anim/anim-btnLoading.json';

const allowedExtensions = ["png", "jpeg"];

export default function ComponentStepD(props) {

    const [token] = useRecoilState(tokenAtom);

    const webcamRef = useRef(null);
    const canvasRef = useRef(null);

    const [files, setFiles] = useState([null, null, null, null]);
    const [previews, setPreviews] = useState([null, null, null, null]);

    const [cap, setCap] = useState('');

    const [submit, setSubmit] = useState(false);
    const [capture, setCapture] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const [, setModelsLoaded] = useState(false);
    const [, setCaptureVideo] = useState(false);

    const alert = useAlert();
    const fileService = new ServiceUtility();
    const appService = new ServiceApplication();

    const formVSchema = Yup.object().shape({
        identityType: Yup.string().required('This information is required'),
        identityNumber: Yup.string().required('This information is required')
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            identityType: '',
            identityNumber: ''
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            if (files.every(file => file !== null)) {
                let promis = [];

                var formDataA = new FormData();
                if (props.data.data.identityInfo) {
                    formDataA.append("path", props.data.data.identityInfo.identityDoc.fileurl);
                    formDataA.append("doc", files[0]);
                    promis.push(fileService.replaceFile(formDataA, token));
                } else {
                    formDataA.append("path", 'user');
                    formDataA.append("doc", files[0]);
                    promis.push(fileService.uploadFile(formDataA, token));
                }

                var formDataB = new FormData();
                if (props.data.data.identityInfo) {
                    formDataB.append("path", props.data.data.identityInfo.frontPic.fileurl);
                    formDataB.append("doc", files[1]);
                    promis.push(fileService.replaceFile(formDataB, token));
                } else {
                    formDataB.append("path", 'user');
                    formDataB.append("doc", files[1]);
                    promis.push(fileService.uploadFile(formDataB, token));
                }

                var formDataC = new FormData();
                if (props.data.data.identityInfo) {
                    formDataC.append("path", props.data.data.identityInfo.smilePic.fileurl);
                    formDataC.append("doc", files[2]);
                    promis.push(fileService.replaceFile(formDataC, token));
                } else {
                    formDataC.append("path", 'user');
                    formDataC.append("doc", files[2]);
                    promis.push(fileService.uploadFile(formDataC, token));
                }

                var formDataD = new FormData();
                if (props.data.data.identityInfo) {
                    formDataD.append("path", props.data.data.identityInfo.profilePic.fileurl);
                    formDataD.append("doc", files[2]);
                    promis.push(fileService.replaceFile(formDataD, token));
                } else {
                    formDataD.append("path", 'user');
                    formDataD.append("doc", files[2]);
                    promis.push(fileService.uploadFile(formDataD, token));
                }

                Promise.all(promis).then((ress) => {
                    let body = {
                        "fid": props.data._id,
                        "opration": "updateStepD",
                        "data": {
                            identityType: values.identityType,
                            identityNumber: values.identityNumber,
                        }
                    }

                    if (files[0] !== null) {
                        body["data"]["identityDoc"] = ress[0].data;
                    } else {
                        body["data"]["identityDoc"] = props.data.data.identityInfo.identityDoc;
                    }

                    if (files[1] !== null) {
                        body["data"]["frontPic"] = ress[1].data;
                    } else {
                        body["data"]["frontPic"] = props.data.data.identityInfo.frontPic;
                    }

                    if (files[2] !== null) {
                        body["data"]["smilePic"] = ress[2].data;
                    } else {
                        body["data"]["smilePic"] = props.data.data.identityInfo.smilePic;
                    }

                    if (files[3] !== null) {
                        body["data"]["profilePic"] = ress[3].data;
                    } else {
                        body["data"]["profilePic"] = props.data.data.identityInfo.profilePic;
                    }

                    appService.updateFormA(body, token).then((res) => {
                        setSubmit(false);
                        if (res.status) {
                            alert.show("Application updated!", { type: 'success' });
                            props.gotoNext(5, res.data);
                        } else {
                            alert.show("Server error, please try again!", { type: 'error' });
                        }
                    });
                });
            } else {
                setSubmit(false);
                alert.show("Please select all the files and try again!", { type: 'error' });
            }
        },
    });

    const handleFileChange = (e, index) => {
        let isError = false;

        const inputFile = e.target.files[0];
        const fileExtension = inputFile?.type.split("/")[1];

        if (!allowedExtensions.includes(fileExtension)) {
            alert.show("Only .png file format is allowed, please try again!", { type: 'error' });
            isError = true;
        }

        if (isError) {
            return;
        }
        const newFiles = [...files];
        newFiles[index] = inputFile;
        setFiles(newFiles);

        const newPreviews = [...previews];
        const reader = new FileReader();
        reader.onload = () => {
            newPreviews[index] = reader.result;
            setPreviews(newPreviews);
        };
        reader.readAsDataURL(inputFile);
    };

    const capturePic = (type) => {
        setCap(type);
        setIsOpen(true);
        setCaptureVideo(true);
        navigator.mediaDevices.getUserMedia({ video: { width: 600 } }).then(stream => {
            let video = webcamRef.current;
            video.srcObject = stream;
            video.play();
        }).catch(err => {
            console.error("error:", err);
        });
    }

    const handleVideoOnPlay = async () => {
        await new Promise((resolve) => setTimeout(resolve, 600));
        setInterval(async () => {
            if (canvasRef && canvasRef.current && webcamRef.current) {
                canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(webcamRef.current);
                const displaySize = {
                    width: 600,
                    height: 450
                }
                faceapi.matchDimensions(canvasRef.current, displaySize);
                const detections = await faceapi.detectAllFaces(webcamRef.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();
                const resizedDetections = faceapi.resizeResults(detections, displaySize);
                canvasRef && canvasRef.current && canvasRef.current.getContext('2d').clearRect(0, 0, 600, 450);
                canvasRef && canvasRef.current && faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
                canvasRef && canvasRef.current && faceapi.draw.drawFaceLandmarks(canvasRef.current, resizedDetections);
                canvasRef && canvasRef.current && faceapi.draw.drawFaceExpressions(canvasRef.current, resizedDetections);
            }
        }, 100);
    }

    const dataURLtoFile = (dataURL) => {
        const byteString = atob(dataURL.split(',')[1]);
        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ab], { type: mimeString });

        const fileType = blob.type;
        const lastModified = Date.now();

        return new File([blob], cap + ".png", { type: fileType, lastModified });
    }

    const getIndexByCap = (type) => {
        switch (type) {
            case 'A':
                return 1;
            case 'B':
                return 2;
            case 'C':
                return 3;
        }
    }

    const captureImage = async () => {
        setCapture(true);

        const canvas = document.createElement('canvas');
        const video = webcamRef.current;

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        const dataURL = canvas.toDataURL('image/png');

        const result = await faceapi.detectAllFaces(webcamRef.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceDescriptors();
        if (result.length === 1) {
            const inputFile = dataURLtoFile(dataURL);
            webcamRef.current.pause();
            webcamRef.current.srcObject.getTracks()[0].stop();
            setCaptureVideo(false);

            const newFiles = [...files];
            newFiles[getIndexByCap(cap)] = inputFile;
            setFiles(newFiles);

            const newPreviews = [...previews];
            const reader = new FileReader();
            reader.onload = () => {
                newPreviews[getIndexByCap(cap)] = reader.result;
                setPreviews(newPreviews);
            };
            reader.readAsDataURL(inputFile);
            setIsOpen(false);
            setCapture(false);
        } else {
            alert.show("Face not found, please try again!", { type: 'error' });
        }
    }

    useEffect(() => {
        if (props.data.data.identityInfo) {
            setValues({ ...values, ...props.data.data.identityInfo });
        }
        const loadModels = async () => {
            const MODEL_URL = process.env.PUBLIC_URL + '/models';
            Promise.all([
                faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
                faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
                faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
                faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
            ]).then(setModelsLoaded(true));
        }
        loadModels();
    }, []);

    return (
        <>
            <Dialog open={isOpen} onClose={() => {
                webcamRef.current.pause();
                webcamRef.current.srcObject.getTracks()[0].stop();
                setCaptureVideo(false);
                setCapture(false);
                setIsOpen(false);
            }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-4xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Image Capture: &nbsp;{
                                (() => {
                                    switch (cap) {
                                        case 'A':
                                            return "Photo of Front Face";
                                        case 'B':
                                            return "Photo of With Smile";
                                        case 'C':
                                            return "Profile Photo";
                                    }
                                })()
                            }
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            webcamRef.current.pause();
                            webcamRef.current.srcObject.getTracks()[0].stop();
                            setCaptureVideo(false);
                            setCapture(false);
                            setIsOpen(false);
                        }}>
                            <i className="las la-times text-sm"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <div className='flex items-center justify-center p-4 relative'>
                        <video ref={webcamRef} height={450} width={600} onPlay={handleVideoOnPlay} style={{ borderRadius: '10px' }} />
                        <canvas ref={canvasRef} style={{ position: 'absolute' }} />
                    </div>
                    <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                        <button type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700" onClick={() => { captureImage() }}>
                            {capture && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!capture && "CAPTURE"}
                        </button>
                    </div>
                </div>
            </Dialog>
            <form onSubmit={handleSubmit}>
                <div className="mt-4 grid grid-cols-12 gap-6">
                    <div className="intro-y mt-5 md:mt-0 col-span-12">
                        <div className="shadow">
                            <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Identity Information</h3>
                                    </div>

                                    <div className="col-span-6 sm:col-span-2">
                                        <label className="block text-xs font-medium text-gray-700 font-mono">Identity Document Number<sup className="text-red-600">*</sup></label>
                                        <input type="text" id="identityNumber" value={values.identityNumber} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. XX-XXX-XXXX" />
                                        {(errors.identityNumber && touched.identityNumber) && <p className='text-xs text-red-400 mt-1'>{errors.identityNumber}</p>}
                                    </div>

                                    <div className="col-span-6 sm:col-span-2">
                                        <label className="block text-xs font-medium text-gray-700 font-mono">Identity Document Type<sup className="text-red-600">*</sup></label>
                                        <select className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" id="identityType" value={values.identityType} onChange={handleChange}>
                                            <option value="">Select Document</option>
                                            <option value="1">Tax ID</option>
                                            <option value="2">Address Prof</option>
                                            <option value="3">Driver Licence</option>
                                        </select>
                                        {(errors.identityType && touched.identityType) && <p className='text-xs text-red-400 mt-1'>{errors.identityType}</p>}
                                    </div>

                                    <div className="col-span-2"></div>

                                    <div className="col-span-6">
                                        <div className="py-2 bg-white">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Identity Document <span className='text-xs text-gray-400'><sup className="text-red-600">*</sup></span>
                                                </label>
                                                {
                                                    (files[0] === null && !props.data.data.identityInfo) && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                        <div className="space-y-1 text-center">
                                                            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            <div className="flex text-sm text-gray-600">
                                                                <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                    <span>Upload a file</span>
                                                                    <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 0) }} />
                                                                </label>
                                                                <p className="pl-1">or drag and drop</p>
                                                            </div>
                                                            <p className="text-xs text-gray-500">
                                                                PNG, JPG, PDF up to 1MB
                                                            </p>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    files[0] !== null && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                        <div className="w-full flex items-center justify-center py-4">
                                                            <img src={previews[0]} className='w-[400px] h-[400px]' />
                                                        </div>
                                                        <hr />
                                                        <div className="flex justify-between mt-2">
                                                            <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                                <span>View</span>
                                                            </label>
                                                            <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                <span>Replace</span>
                                                                <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 0) }} />
                                                            </label>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (files[0] === null && props.data.data.identityInfo) && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                        <div className="w-full flex items-center justify-center py-4">
                                                            <img src={props.data.data.identityInfo.identityDoc.fileurl} className='w-[400px] h-[400px]' />
                                                        </div>
                                                        <hr />
                                                        <div className="flex justify-between mt-2">
                                                            <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                                <span>View</span>
                                                            </label>
                                                            <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                <span>Replace</span>
                                                                <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 0) }} />
                                                            </label>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-span-6 md:col-span-2">
                                        <div className="py-2 bg-white">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Photo of Front Face <span className='text-xs text-gray-400'><sup className="text-red-600">*</sup></span>
                                                </label>
                                                {
                                                    (files[1] === null && !props.data.data.identityInfo) && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                        <div className="space-y-1 text-center">
                                                            <i className="las la-camera-retro text-6xl text-gray-400"></i>
                                                            <div className="flex text-sm text-gray-600">
                                                                <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500" onClick={() => { capturePic('A') }}>
                                                                    <span>Take A Photo</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    files[1] !== null && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                        <div className="w-full flex items-center justify-center py-4">
                                                            <img src={previews[1]} className='w-[400px] h-[400px]' />
                                                        </div>
                                                        <hr />
                                                        <div className="flex justify-between mt-2">
                                                            <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                                <span>View</span>
                                                            </label>
                                                            <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono" onClick={() => { capturePic('A') }}>
                                                                <span>Replace</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (files[1] === null && props.data.data.identityInfo) && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                        <div className="w-full flex items-center justify-center py-4">
                                                            <img src={props.data.data.identityInfo.frontPic.fileurl} className='w-[400px] h-[400px]' />
                                                        </div>
                                                        <hr />
                                                        <div className="flex justify-between mt-2">
                                                            <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                                <span>View</span>
                                                            </label>
                                                            <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono" onClick={() => { capturePic('A') }}>
                                                                <span>Replace</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-span-6 md:col-span-2">
                                        <div className="py-2 bg-white">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Photo of With Smile <span className='text-xs text-gray-400'><sup className="text-red-600">*</sup></span>
                                                </label>
                                                {
                                                    (files[2] === null && !props.data.data.identityInfo) && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                        <div className="space-y-1 text-center">
                                                            <i className="las la-camera-retro text-6xl text-gray-400"></i>
                                                            <div className="flex text-sm text-gray-600">
                                                                <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500" onClick={() => { capturePic('B') }}>
                                                                    <span>Take A Photo</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    files[2] !== null && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                        <div className="w-full flex items-center justify-center py-4">
                                                            <img src={previews[2]} className='w-[400px] h-[400px]' />
                                                        </div>
                                                        <hr />
                                                        <div className="flex justify-between mt-2">
                                                            <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                                <span>View</span>
                                                            </label>
                                                            <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono" onClick={() => { capturePic('B') }}>
                                                                <span>Replace</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (files[2] === null && props.data.data.identityInfo) && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                        <div className="w-full flex items-center justify-center py-4">
                                                            <img src={props.data.data.identityInfo.smilePic.fileurl} className='w-[400px] h-[400px]' />
                                                        </div>
                                                        <hr />
                                                        <div className="flex justify-between mt-2">
                                                            <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                                <span>View</span>
                                                            </label>
                                                            <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono" onClick={() => { capturePic('B') }}>
                                                                <span>Replace</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-span-6 md:col-span-2">
                                        <div className="py-2 bg-white">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Profile Photo <span className='text-xs text-gray-400'><sup className="text-red-600">*</sup></span>
                                                </label>
                                                {
                                                    (files[3] === null && !props.data.data.identityInfo) && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                        <div className="space-y-1 text-center">
                                                            <i className="las la-camera-retro text-6xl text-gray-400"></i>
                                                            <div className="flex text-sm text-gray-600">
                                                                <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500" onClick={() => { capturePic('C') }}>
                                                                    <span>Take A Photo</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    files[3] !== null && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                        <div className="w-full flex items-center justify-center py-4">
                                                            <img src={previews[3]} className='w-[400px] h-[400px]' />
                                                        </div>
                                                        <hr />
                                                        <div className="flex justify-between mt-2">
                                                            <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                                <span>View</span>
                                                            </label>
                                                            <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono" onClick={() => { capturePic('C') }}>
                                                                <span>Replace</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (files[3] === null && props.data.data.identityInfo) && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                        <div className="w-full flex items-center justify-center py-4">
                                                            <img src={props.data.data.identityInfo.profilePic.fileurl} className='w-[400px] h-[400px]' />
                                                        </div>
                                                        <hr />
                                                        <div className="flex justify-between mt-2">
                                                            <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                                <span>View</span>
                                                            </label>
                                                            <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono" onClick={() => { capturePic('C') }}>
                                                                <span>Replace</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="intro-y hidden sm:block">
                    <div className="py-5">
                        <div className="border-t border-gray-200"></div>
                    </div>
                </div>
                <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                    <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700">
                        {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                        {!submit && "SAVE & NEXT"}
                    </button>
                </div>
            </form>
        </>
    )
}
